// Generic Confirm Modal component
import Modal from '@/components/Modal/Modal';

interface ConfirmProps {
  isOpen: boolean;
  content: React.JSX.Element | string | boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  confirmBtnLabel?: string;
}
export default function Confirm({
  isOpen,
  content,
  onCancel,
  isLoading,
  onConfirm,
  confirmBtnLabel = 'Confirm',
}: ConfirmProps) {
  return (
    <Modal isOpen={isOpen}>
      <>
        <div>{content}</div>
        <div className="modal-action">
          <button className="btn grow" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn btn-primary grow"
            disabled={isLoading}
            onClick={onConfirm}
          >
            {confirmBtnLabel}
            {isLoading && (
              <span className="loading loading-spinner loading-xs"></span>
            )}
          </button>
        </div>
      </>
    </Modal>
  );
}
