// Generic Modal component
import React, { useEffect, useRef } from 'react';

interface ModalProps {
  isOpen: boolean;
  children: React.JSX.Element | boolean;
}
export default function Modal({ isOpen, children }: ModalProps) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog ref={dialogRef} className="modal">
      <div className="prose modal-box max-w-fit">{children}</div>
    </dialog>
  );
}
