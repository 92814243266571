// Helper module for User Roles and Permissions management
import { OperatorTruckIndexRecord } from '@isheepdog/operator-search';

import { get } from 'lodash';

import { getUserCompanyId } from '@/lib/firebase/db/helpers';
import { JobDoc, OperatorTruckDoc, UserDoc } from '@/lib/firebase/db/metaTypes';

import { FEATURES, getIsFeatureEnabled } from '@/lib/helpers/features';

/**
 * Roles, Permission Groups and Access Level definition
 */

export type UserRole =
  | 'broker'
  | 'operatorEmployee'
  | 'operatorOwner'
  | 'operatorIndependent'
  | 'clientAccounting'
  | 'clientDispatch'
  | 'clientExecutive'
  | 'clientForeman'
  | 'clientWeighmaster'
  | 'clientSupervisor'
  | 'ironSheepdogSupport';

export type UserPermissionGroup =
  | 'poster'
  | 'posterAssigner'
  | 'driver'
  | 'payee'
  | 'tracker'
  | 'verifier'
  | 'biller'
  | 'payer'
  | 'setupPaver'
  | 'externalInviter'
  | 'regionalAdmin'
  | 'taskAuditor'
  | 'taskEditor'
  | 'rateViewer';

export type UserAccessLevel = 'normal' | 'admin';

type RoleSubject = UserDoc | OperatorTruckDoc | OperatorTruckIndexRecord;

/**
 * User Roles and permission groups helper functions
 */

/**
 * Determines the role of a given subject, which can be a user or an operator truck document.
 * This function extracts the role information from the document's data, accommodating the different structures that may contain role information.
 * It's designed to be flexible, allowing for the retrieval of role information from a variety of document types within the system.
 *
 * @param {RoleSubject} userDoc - The document from which to extract role information. Can be either a UserDoc or an OperatorTruckDoc.
 * @returns {UserRole | ''} The role of the subject if available, or an empty string if the role cannot be determined.
 */
export function getRole(userDoc: RoleSubject): UserRole | '' {
  const userData = 'data' in userDoc ? userDoc.data() : userDoc;
  const userRole = get(userData, 'writeNever.role') as unknown as UserRole;
  const operatorTruckRole = get(
    userData,
    'operator.role'
  ) as unknown as UserRole;
  return userRole || operatorTruckRole || '';
}

/**
 * Retrieves the permission groups associated with a given subject, which can be a user or an operator truck document.
 * This function looks into the document's data to extract permission group information, considering the different data structures for users and operator trucks.
 * It's designed to consolidate the approach to obtaining permission groups, facilitating access control and permissions management across different types of subjects.
 *
 * @param {RoleSubject} userDoc - The document from which to extract permission group information. Can be either a UserDoc or an OperatorTruckDoc.
 * @returns {UserPermissionGroup[]} An array of permission groups associated with the subject, or an empty array if no permission groups are found.
 */
export function getPermissionGroups(
  userDoc: RoleSubject
): UserPermissionGroup[] {
  const userData = 'data' in userDoc ? userDoc.data() : userDoc;
  const userPermissionGroups = get(
    userData,
    'writeNever.permissionGroups'
  ) as unknown as UserPermissionGroup[];
  const operatorTruckPermissionGroups = get(
    userData,
    'operator.permissionGroups'
  ) as unknown as UserPermissionGroup[];
  return userPermissionGroups || operatorTruckPermissionGroups || [];
}

/**
 * Retrieves the access level of a given subject, which can be either a user or an operator truck document.
 *
 * @param {RoleSubject} userDoc - The document from which to extract access level information. Can be either a UserDoc or an OperatorTruckDoc.
 * @returns {UserAccessLevel | ''} The access level associated with the subject if available, or an empty string if the access level cannot be determined.
 */
export function getAccessLevel(userDoc: RoleSubject): UserAccessLevel | '' {
  const userData = 'data' in userDoc ? userDoc.data() : userDoc;
  const userAccessLevel = get(
    userData,
    'writeNever.accessLevel'
  ) as unknown as UserAccessLevel;
  const operatorTruckAccessLevel = get(
    userData,
    'operator.accessLevel'
  ) as unknown as UserAccessLevel;
  return userAccessLevel || operatorTruckAccessLevel || '';
}

/**
 * Checks if the specified subject has a specific role.
 *
 * @param {UserRole} role - The role to check against the subject's role.
 * @param {RoleSubject} userDoc - The document of the subject whose role is being checked. Can be either a UserDoc or an OperatorTruckDoc.
 * @returns {boolean} True if the subject's role matches the specified role, false otherwise.
 */
export function isRole(role: UserRole) {
  return (userDoc: RoleSubject) => getRole(userDoc) === role;
}

/**
 * Checks if the specified user document is part of a given permission group.
 *
 * @param {UserPermissionGroup} permissionGroup - The permission group to check for within the user document.
 * @param {UserDoc} userDoc - The user document to inspect for the specified permission group.
 * @returns {boolean} True if the user document includes the specified permission group, false otherwise.
 */
export function isPermissionGroup(permissionGroup: UserPermissionGroup) {
  return (userDoc: RoleSubject) =>
    isSupport(userDoc) ||
    getPermissionGroups(userDoc).includes(permissionGroup);
}

/**
 * Determines if the specified subject has a particular access level.
 *
 * @param {UserAccessLevel} accessLevel - The access level to check against the subject's access level.
 * @param {RoleSubject} userDoc - The document of the subject whose access level is being checked. Can be either a UserDoc or an OperatorTruckDoc.
 * @returns {boolean} True if the subject's access level matches the specified access level, false otherwise.
 */
export function isAccessLevel(accessLevel: UserAccessLevel) {
  return (userDoc: RoleSubject) => getAccessLevel(userDoc) === accessLevel;
}

export const isSupport = isRole('ironSheepdogSupport');

export const isBroker = isRole('broker');

export const isClient = (userDoc: RoleSubject) => {
  const userRole = getRole(userDoc);
  return userRole.startsWith('client');
};

export function isInviter(userDoc: RoleSubject) {
  return isAccessLevel('admin')(userDoc);
}

/**
 * Determines if a user is an assigner
 * Assigners can be owner operators, hybrid brokers or brokers
 * who can delegate to other brokers.
 * @returns {boolean} - Returns true if the user is an assigner, false otherwise.
 */
export function isAssigner(userDoc: RoleSubject) {
  return (
    (isPermissionGroup('driver')(userDoc) && isInviter(userDoc)) ||
    canBrokerDelegateToBroker(userDoc)
  );
}

export function canBrokerDelegateToBroker(userDoc: RoleSubject) {
  const isUserBroker = isRole('broker')(userDoc);
  const canDelegateToBroker = canUserDelegateToBroker(userDoc);
  return isUserBroker && canDelegateToBroker;
}

export function canUserDelegateToBroker(userDoc: RoleSubject) {
  // Enable for all Broker roles except for ones that have it explicitly disabled
  const isBroker = isRole('broker')(userDoc);
  const isUserDoc = 'ref' in userDoc;
  const isFeatureEnabled =
    isUserDoc &&
    getIsFeatureEnabled(FEATURES.DELEGATE_TO_BROKER, userDoc as UserDoc) !==
      false;
  return isBroker && isUserDoc && isFeatureEnabled;
}

export function canUserEditJobBrokerCompany(
  userDoc: RoleSubject,
  jobDoc: JobDoc
) {
  const isClientWithPosterPermissions =
    isClient(userDoc) && isPermissionGroup('posterAssigner')(userDoc);
  const isJobClient =
    'get' in userDoc &&
    getUserCompanyId(userDoc as UserDoc) === jobDoc.get('client')?.id;
  return isClientWithPosterPermissions && isJobClient;
}
