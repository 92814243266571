import {
  DeepValue,
  DocumentSnapshot,
  MetaType,
  ObjectFlatten,
} from 'firelordjs/dist/types';

import { get } from 'lodash';

/**
 * Represents a simplified, fake version of a Firestore DocumentSnapshot for testing purposes.
 * This type is particularly useful in scenarios where tests need to interact with a mock Firestore document,
 * simulating the retrieval of document data without accessing the actual Firestore.
 *
 * @template T - A generic type extending `MetaType`, which typically encapsulates the structure of Firestore documents,
 * including their readable and writable properties.
 *
 * @type {DocumentSnapshot<T>} - A type that picks specific properties from `DocumentSnapshot<T>` essential for
 * simulating document behavior in tests. These properties include:
 * - `data`: A function that returns the document's data.
 * - `get`: A function that allows accessing a specific field's value within the document.
 * - `id`: The document's ID as a string.
 */
export type FakeDocumentSnapshot<T extends MetaType> = {
  _data: DeepPartial<T['read']>;
  ref: { id: string };
} & Pick<DocumentSnapshot<T>, 'data' | 'get' | 'id'>;

/**
 * Represents a type that recursively makes all properties of `T` optional.
 * The `DeepPartial` type is useful when you want to create types based on a given type but with all properties,
 * including nested properties, marked as optional. This is particularly handy in scenarios such as creating
 * partial update payloads for database entities or when working with complex nested structures where only
 * a subset of properties needs to be provided.
 *
 * @template T - The base type to be transformed into a deeply partial type.
 *
 * @type {Array} If `T` extends an array, it returns the same array type `T` without modifying it.
 * This is because the intention is to make the properties of the objects within the array optional,
 * not the array elements themselves.
 *
 * @type {Record} If `T` extends a `Record<string, any>`, it transforms `T` into a type where every property `P`
 * of `T` is optional (`?`). Furthermore, for each property `P`, it applies `DeepPartial` recursively,
 * making the properties of `P` optional as well. This recursive application continues until all nested properties
 * are marked as optional.
 *
 * @type {T} If `T` does not extend an array or `Record<string, any>`, it returns the type `T` unchanged.
 * This is the base case for the recursion, typically applying to primitive types within the structure of `T`.
 */
export type DeepPartial<T> = T extends any[]
  ? T
  : T extends Record<string, any>
    ? {
        [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

/**
 * Creates a fake document snapshot for testing purposes, simulating Firestore document snapshots.
 * This function is designed to help with unit and integration testing by allowing developers to create mock
 * Firestore document snapshots. It accepts partial data conforming to the structure expected by a Firestore document,
 * including nested fields, and provides a method to retrieve field values using a field path.
 *
 * @template T - A type parameter extending MetaType, which includes 'write' and 'read' type properties to represent
 * the structure of Firestore documents.
 * @param {Partial<T['write']>} data - Partial data object representing the writable fields of the Firestore document.
 * @returns {FakeDocumentSnapshot<T>} A fake document snapshot object with an 'id' property and a 'get' method for
 * accessing field values by their paths.
 */
export function createFakeDocument<T extends MetaType>(
  id: string,
  data: DeepPartial<T['write']>
) {
  function getFieldPath<FieldPath extends keyof T['writeFlatten'] & string>(
    fieldPath: FieldPath
  ) {
    const value = get(data, fieldPath) as
      | DeepValue<ObjectFlatten<T['read'], never>, FieldPath>
      | undefined;
    return value;
  }
  const fakeDocSnapshot: FakeDocumentSnapshot<T> = {
    id,
    ref: { id },
    get: getFieldPath,
    data: () => data,
    _data: data,
  };
  return fakeDocSnapshot;
}
