import { getFunctions, httpsCallable } from 'firebase/functions';

import { AssignmentsUpdateOperation } from '@/lib/context/EditSchedulerContext';

import { firebaseApp } from '@/lib/firebase/firebase';

const functions = getFunctions(firebaseApp);

type AddOperatorToJobParams = {
  jobId: string;
  userId: string;
  groupIndex: number;
  trucksNumberToReassign: number;
};
export const addOperatorToJob = httpsCallable<AddOperatorToJobParams>(
  functions,
  'addOperatorToJob'
);

type RemoveOperatorFromJobParams = {
  jobId: string;
  jobAssignmentId: string;
};
export const removeOperatorFromJob = httpsCallable<RemoveOperatorFromJobParams>(
  functions,
  'removeOperatorFromJob'
);

type JobAssignmentsBatchUpdateParams = {
  operations: Pick<
    AssignmentsUpdateOperation,
    | 'jobId'
    | 'type'
    | 'userId'
    | 'groupIndex'
    | 'trucksNumberToReassign'
    | 'jobAssignmentId'
  >[];
};
export const jobAssignmentsBatchUpdate =
  httpsCallable<JobAssignmentsBatchUpdateParams>(
    functions,
    'jobAssignmentsBatchUpdate'
  );
