// Provide access to URL parameters for any consumer
import React, { useContext, useEffect, useState } from 'react';

import { useSearchParams } from 'next/navigation';

import { fromPairs } from 'lodash';

// List of all the expected URL params
const urlParams = [
  'schedule',
  'date',
  'operators',
  'sortBy',
  'order',
  'filters',
] as const;
export type URLParams = (typeof urlParams)[number];

type URLParamsContextType = {
  updateParam: (param: URLParams, value: string) => void;
} & Record<URLParams, string>;

export const URLParamsContext = React.createContext<URLParamsContextType>(
  Object.assign(
    { updateParam: () => null },
    fromPairs(urlParams.map((p) => [p, ''])) as Record<URLParams, string>
  )
);

export const useURLParamsContext = () => useContext(URLParamsContext);

export const URLParamsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const searchParams = useSearchParams();
  const [schedule, setSchedule] = useState('');
  const [date, setDate] = useState('');
  const [operators, setOperators] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [order, setOrder] = useState('');
  const [filters, setFilters] = useState('');

  const updaters: Record<URLParams, Function> = {
    schedule: setSchedule,
    date: setDate,
    operators: setOperators,
    sortBy: setSortBy,
    order: setOrder,
    filters: setFilters,
  };

  useEffect(() => {
    urlParams.forEach((param) => {
      updaters[param](searchParams.get(param) || '');
    });
  }, []);

  function updateParam(param: string, value: string) {
    const url = new URL(window.location.href);
    if (!value) {
      url.searchParams.delete(param);
    } else {
      url.searchParams.set(param, value);
    }
    window.history.pushState({}, '', url);
  }

  return (
    <URLParamsContext.Provider
      value={{
        updateParam,
        schedule,
        date,
        operators,
        sortBy,
        order,
        filters,
      }}
    >
      {children}
    </URLParamsContext.Provider>
  );
};
